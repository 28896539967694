import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'commerceDashboard',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setBuildingDistribution: (state, action) => {
      state.buildingDistribution = action.payload
    },
    setComparativeData: (state, action) => {
      state.comparativeData = action.payload
    },
    setTemporalDistribution: (state, action) => {
      state.temporalDistribution = action.payload
    },

    setTodayTickets: (state, action) => {
      state.todayTickets = action.payload
    },
    setTicketsHistory: (state, action) => {
      state.ticketsHistory = action.payload
    },
    setUsersType: (state, action) => {
      state.usersType = action.payload
    },

    setCommercesDashboardFilters: (state, action) => {
      state.filters = action.payload
    },
    setComparativeTotal: (state, action) => {
      state.comparativeTotal = action.payload
    },
    setGeneral: (state, action) => {
      state.general = action.payload
    }
  }
})

export const {
  setGeneral,
  setLoading,
  setData,
  setCommercesDashboardFilters,
  setComparativeData,
  setTemporalDistribution,
  setTodayTickets,
  setUsersType,
  setTicketsHistory,
  setBuildingDistribution,
  setComparativeTotal
} = slice.actions

export const commerceDashboard = slice.reducer
