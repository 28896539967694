// ** Icons Import
import {
  Bluetooth,
  Download,
  Settings,
  Smartphone,
  List,
  Bookmark,
  Star,
  Circle,
  CheckSquare,
  DollarSign,
  FileText,
  File
} from 'react-feather'

const billIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-receipt-2'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2' />
    <path d='M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5' />
  </svg>
)

const commercePrepaid = [
  {
    header: 'Estacionamiento',
    action: 'comercio-estacionamiento-prepaid',
    resource: 'Common'
  },
  {
    id: 'TicketsDigitizePrepaid',
    title: 'Digitalizar Tickets',
    action: 'comercio-estacionamiento-prepaid',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  }
]

const commercePostpaid = [
  {
    header: 'Estacionamiento',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common'
  },
  {
    id: 'TicketsDigitizePost',
    title: 'Digitalizar Tickets',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  },
  {
    id: 'TicketsInvoices',
    title: 'Recibos',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common',
    icon: <DollarSign size={20} />,
    navLink: '/parking-lot/invoices'
  }
]

export default [
  {
    header: 'Estacionamiento',
    action: 'estacionamiento-level-0',
    resource: 'Common'
  },
  {
    id: 'TicketsHistory',
    title: 'Historial de Tickets',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <List size={20} />,
    navLink: '/parking-lot/tickets-history'
  },

  {
    id: 'TicketsDigitize',
    title: 'Digitalizar Tickets',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  },
  // {
  //   id: 'PayParkingTickets',
  //   title: 'Pagar tickets',
  //   action: 'estacionamiento-level-0',
  //   resource: 'Common',
  //   icon: <Smartphone size={20} />,
  //   navLink: '/parking-lot/tickets-pay'
  // },
  // {
  //   id: 'InfoParkingTicketsPhysic',
  //   title: 'Ticket físico info',
  //   action: 'estacionamiento-level-0',
  //   resource: 'Common',
  //   icon: <FileText size={20} />,
  //   navLink: '/parking-lot/validate-ticket'
  // },
  {
    id: 'Premium',
    title: 'Premium',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Star size={20} />,
    children: [
      {
        id: 'Activation',
        title: 'Activación',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/activation'
      },
      {
        id: 'History',
        title: 'Historial',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/history'
      }
      /*       {
        id: 'Invoices',
        title: 'Recibos',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/invoices'
      } */
    ]
  },
  {
    id: 'ConnectionReports',
    title: 'Informes de conexión',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Bluetooth size={20} />,
    navLink: '/parking-lot/connection-reports'
  },
  {
    id: 'ParkingLotCommerces',
    title: 'Commercios',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Bookmark size={20} />,
    navLink: '/parking-lot/commerces'
  },
  {
    id: 'ParkingLotReports',
    title: 'Reportes',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Download size={20} />,
    navLink: '/parking-lot/reports'
  },
  {
    id: 'ParkingLotSettings',
    title: 'Ajustes',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Settings size={20} />,
    navLink: '/parking-lot/settings'
  },

  {
    header: 'Ticket físico',
    action: 'common',
    resource: 'Common'
  },
  {
    id: 'pay-tickets-parking-commerces',
    title: 'Pagar tickets',
    action: 'comercio-estacionamiento-prepaid',
    resource: 'Common',
    icon: billIcon,
    navLink: 'parking-lot/tickets-pay'
  },
  {
    id: 'pay-tickets-parking-commerces',
    title: 'Pagar tickets',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: billIcon,
    navLink: 'parking-lot/tickets-pay'
  },
  {
    id: 'ticket-info-parking-commerces',
    title: 'Ticket físico info',

    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <File size={20} />,
    navLink: 'parking-lot/validate-ticket'
  },
  ...commercePostpaid,
  ...commercePrepaid
]
