import { act } from 'react'

export const defaultFilter = {
  commerce_id: '60711dd88a0f3e001529d719',
  from_date: '',
  end_date: '',
  skip: 0,
  limit: 10,
  user_id: '',
  type: '',
  currency: 'BS',
  transaction_tag: '',
  descripcion_tag: '',
  status: '',
  user_extension: false
}

export const defaultFilterWithdraw = {
  commerce_id: '60711dd88a0f3e001529d719',
  from_date: '',
  end_date: '',
  skip: '',
  limit: 11,
  user_id: '',
  type: 'debit',
  currency: 'BS',
  transaction_tag: 'withdraw',
  status: '',
  user_extension: false
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  update: { action: 'update', message: 'Actualizar extensión' },
  delete: { action: 'deleted', message: 'eliminar' },
  reversePremium: { action: 'reversePremium', message: 'reversar premium' },
  reverseParkingMeter: {
    action: 'reverseParkingMeter',
    message: 'reversar parquímetros'
  },
  reverseToll: { action: 'reverseToll', message: 'reversar peaje' },
  reverseParking: { action: 'reverseParking', message: 'reversar parqueo' },
  reverseParkingOvertime: {
    action: 'reverseParkingOvertime',
    message: 'reversar sobre tiempo'
  }
}

const statusOptions = {
  pending: { label: 'Pendiente', color: 'light-warning' },
  approved: { label: 'Aprobado', color: 'light-success' },
  rejected: { label: 'Rechazado', color: 'light-danger' }
}

const canReverse = [
  'parking_overtime',
  'parking',
  'parking_premium_bundle',
  'parking_premium',
  'parkingmeters_new_ticket',
  'toll'
]

const initialState = {
  loading: false,
  data: [],

  canReverse,
  filters: defaultFilter,

  statusOptions,

  tagsOptions: {},

  transactionTagsOptions: [],
  descriptionTagsOptions: [],

  selectedExtensionList: [],
  selectedTransaction: null,

  mobilePaidOptions: [],

  nextPageExists: false,
  actions: defaultActions
}

export default initialState
