export * from './utils'
import { apiMethods } from '@services/methods'
import { baseV2URL, baseV1URL, physicalTicket } from '@configs/apis'

const endpoints = {
  dateRange: `${baseV2URL}/tickets/history`,
  autocomplete: `${baseV2URL}/tickets/autocomplete`,
  discard: `${baseV2URL}/superadmin/tickets/discarded`,
  commerceTicketsAvailability: `${baseV1URL}/commerces/tickets/availability`,
  refound: `${baseV2URL}/superadmin/reverse/parking`,
  close: `${baseV1URL}/superadmin/tickets/closed`,

  payPhysicalTicket: `${physicalTicket}/InitiateStateMachine`,
  getBuildingInfoPhysicalTicket: `${physicalTicket}/GetBuildingsFromTicket`,
  statusPhysicalTicket: `${physicalTicket}/ValidateTicketForExit`,
  getInfoBuildingTicket: `${physicalTicket}/GetInfoBuildingTicket`,

  getBuildingTicketAmount: `${baseV2URL}/superadmin/ticket/amount`,
  getUserByDocument: `${baseV1URL}/superadmin/customer`
}

export const getUserByDocumentService = async (document) => {
  try {
    const url = `${endpoints.getUserByDocument}/${document}`

    const { ok, res, error } = await apiMethods.authGetFetch(url)
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getInfoBuildingTicketService = async (body) => {
  try {
    const url = endpoints.getInfoBuildingTicket
    const { ok, res, error } = await apiMethods.authPostFetchLambda({
      url,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getBuildingTicketAmountService = async (body) => {
  try {
    const url = endpoints.getBuildingTicketAmount
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getBuildingsByTicketService = async (body) => {
  try {
    const url = endpoints.getBuildingInfoPhysicalTicket
    const { ok, res, error } = await apiMethods.authPostFetchLambda({
      url,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const payTicketsService = async (body) => {
  try {
    const url = endpoints.payPhysicalTicket
    const { ok, res, error } = await apiMethods.authPostFetchLambda({
      url,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const checkTicketStatusService = async (body) => {
  try {
    // TODO: aca
    const url = endpoints.statusPhysicalTicket

    // body.building_code_pd = '0025'
    const { ok, res, error } = await apiMethods.authPostFetchLambda({
      url,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const autocomplete = async ({
  searchParam: input,
  building,
  status = [],
  types = [],
  creationRule,
  toPay: to_pay,
  page: skip,
  show: limit,
  commerce
}) => {
  try {
    const url = endpoints.autocomplete
    const body = {
      input,
      status,
      types,
      creationRule,
      to_pay,
      skip: skip * limit,
      limit,
      commerce,
      building
    }
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const dateRange = async ({
  fromDate: from_date,
  endDate: end_date,
  status = [],
  types = [],
  creationRule,
  page: skip,
  show: limit,
  commerce,
  building,
  newUser: new_user
}) => {
  const url = endpoints.dateRange
  const body = {
    from_date,
    end_date,
    status,
    types,
    creationRule,
    skip: skip * limit,
    limit,
    commerce,
    building,
    new_user
  }
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}

export const discard = async ({ id, username }) => {
  const url = endpoints.discard
  const body = { id, username }
  const { ok, res, error } = await apiMethods.authPatchFetch({ url, body })
  return { ok, res, error }
}

export const refound = async (body) => {
  const url = endpoints.refound
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}

export const commerceTicketsAvailability = async ({ customer }) => {
  const url = endpoints.commerceTicketsAvailability
  const body = { customer }
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}

export const closeTicketService = async (body) => {
  const url = endpoints.close
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}
