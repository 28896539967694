import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import { getUsersMetricService } from '../../../services/users/dashboard'
import {
  setActiveUsers,
  setBuildingDistribution,
  setComparativeData,
  setComparativeTotal,
  setGeneral,
  setLoading,
  setNewUsers,
  setTemporalDistribution,
  setTicketsHistory,
  setTodayTickets,
  setUsersType
} from './slice'
import moment from 'moment'
import { getCommercesMetricService } from '../../../services/commerces/dashboard'

const dailyHours = Array.from(
  { length: 24 },
  (_, i) => `${i.toString().padStart(2, '0')}:00`
)
const weeklyDays = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom']

const formatData = (data, format) =>
  data.reduce(
    (acc, { dateFormatted, qty }) => {
      acc[0].push(moment(dateFormatted).format(format))
      acc[1].push(qty)
      return acc
    },
    [[], []]
  )
// This function is not used in the code

const todayMetrics = (todayTickets, dispatch) => {
  dispatch(
    setTodayTickets({
      currentQty: todayTickets?.today_qty,
      previousQty: todayTickets?.yesterday_qty,
      variation: todayTickets?.variation
    })
  )
}

const lastTickets = (data, dispatch) => {
  const ticketsHistory = data?.map((data) => {
    return {
      ticket_number: data?.ticket_number,
      building: data?.building?.name,
      date: moment(data.created_at).format('YYYY-MM-DD HH:mm'),
      username: data?.username
    }
  })
  dispatch(setTicketsHistory(ticketsHistory))
}

const temporalDistribution = (data, dispatch) => {
  const [xAxis, yAxis] = formatData(data, 'YYYY-MM-DD')
  dispatch(setTemporalDistribution({ xAxis, yAxis }))
}

const accumulateMetrics = (currentData, previousData, dispatch) => {
  if (currentData.length === 0) {
    currentData = previousData
    previousData = []
  }

  const transformArrayToObject = (array, key) => {
    return array.reduce((acc, item) => {
      acc[item[key]] = item.qty
      return acc
    }, {})
  }

  dispatch(
    setGeneral({
      currentStatus: transformArrayToObject(currentData.status || [], 'status'),
      previousStatus: transformArrayToObject(
        previousData.status || [],
        'status'
      ),
      currentTypes: transformArrayToObject(currentData.types || [], 'type'),
      previousTypes: transformArrayToObject(previousData.types || [], 'type')
    })
  )

  dispatch(
    setComparativeTotal({ current: currentData, previous: previousData })
  )
}

const reduceBuildingData = (data) => {
  const [xAxis, yAxis] = data.reduce(
    (acc, data) => {
      acc[0].push(data.building)
      acc[1].push(data.qty)
      return acc
    },
    [[], []]
  )
  return [xAxis, yAxis]
}

const buildingDistribution = (currentData, previousData, dispatch) => {
  if (currentData.length === 0) {
    currentData = previousData
    previousData = []
  }

  const [xAxisCurrentBuilding, yAxisCurrentBuilding] =
    reduceBuildingData(currentData)
  const [xAxisPreviousBuilding, yAxisPreviousBuilding] =
    reduceBuildingData(previousData)

  dispatch(
    setBuildingDistribution({
      xAxisCurrentBuilding,
      yAxisCurrentBuilding,
      xAxisPreviousBuilding,
      yAxisPreviousBuilding
    })
  )
}

export const getCommercesMetrics = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().commerceDashboard
      const { ok, res, error } = await getCommercesMetricService(filters)

      if (!ok) throw error

      const {
        period_tickets,
        tickets_distribution,
        today_tickets_relevance: todayTickets,
        history_tickets,
        period_range_qtys
      } = res

      const lastTicketsList = history_tickets?.tickets
      const currentData = period_tickets[1] || []
      const previousData = period_tickets[0] || []

      todayMetrics(todayTickets, dispatch)
      lastTickets(lastTicketsList, dispatch)
      temporalDistribution(tickets_distribution, dispatch)

      if (filters.period !== 'range') {
        const currentBuildingData = period_tickets[1]?.buildings || []
        const previousBuildingData = period_tickets[0]?.buildings || []

        buildingDistribution(
          currentBuildingData,
          previousBuildingData,
          dispatch
        )

        accumulateMetrics(currentData, previousData, dispatch)
      } else {
        const currentBuildingData = res?.period_range_qtys?.buildings || []
        const previousBuildingData = []

        buildingDistribution(
          currentBuildingData,
          previousBuildingData,
          dispatch
        )
        accumulateMetrics(period_range_qtys, [], dispatch)
      }

      if (filters.period === 'daily') {
        const xAxis = dailyHours
        const formatHour = (hour) => hour.padStart(2, '0')

        const qtyByHourPrevious = dailyHours.map((hour) => {
          const hourData = previousData?.hours?.find(
            (h) => formatHour(h.hourFormatted) === hour.split(':')[0]
          )
          return hourData ? hourData.qty : 0
        })
        const qtyByHourCurrent = dailyHours.map((hour, index) => {
          const hourData = currentData?.hours?.find(
            (h) => formatHour(h.hourFormatted) === hour.split(':')[0]
          )
          return hourData ? hourData.qty : 0
        })

        dispatch(
          setComparativeData({
            yAxisCurrent: qtyByHourCurrent,
            yAxisPrevious: qtyByHourPrevious,
            xAxis
          })
        )
      }

      if (filters.period === 'weekly') {
        // const xAxis = weeklyDays
        const currentWeeklyStart = moment().startOf('week').format('YYYY-MM-DD')
        const previousWeeklyStart = moment()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD')

        const generateWeekDates = (start) => {
          return Array.from({ length: 7 }, (_, i) =>
            moment(start).add(i, 'days').format('YYYY-MM-DD')
          )
        }

        const weeklyNumberDaysByDays = generateWeekDates(currentWeeklyStart)
        const previousWeeklyNumberDaysByDays =
          generateWeekDates(previousWeeklyStart)

        const current = currentData?.days ?? previousData?.days ?? []
        const previous = previousData?.days || []

        const days = []
        const yAxisCurrent = []
        const yAxisPrevious = []
        const numberOfDays = 7
        for (let index = 0; index < numberOfDays; index++) {
          days.push(index + 1)
          const currentDate = weeklyNumberDaysByDays[index]
          const previousDate = previousWeeklyNumberDaysByDays[index]

          const currentDayQty = current.find(
            (day) => day.dateFormatted === currentDate
          )

          const previousDayQty = previous.find(
            (day) => day.dateFormatted === previousDate
          )
          yAxisCurrent.push(currentDayQty ? currentDayQty.qty : 0)
          yAxisPrevious.push(previousDayQty ? previousDayQty.qty : 0)
        }

        dispatch(
          setComparativeData({
            yAxisCurrent,
            yAxisPrevious,
            xAxis: weeklyDays
          })
        )
      }

      if (filters.period === 'monthly') {
        const currentMonthlyStart = moment()
          .startOf('month')
          .format('YYYY-MM-DD')

        const previousMonthlyStart = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')

        const monthlyNumberDaysByDays = Array.from(
          { length: moment().daysInMonth() },
          (v, i) =>
            moment(currentMonthlyStart).add(i, 'days').format('YYYY-MM-DD')
        )

        const previousMonthlyNumberDaysByDays = Array.from(
          { length: moment().subtract(1, 'month').daysInMonth() },
          (v, i) =>
            moment(previousMonthlyStart).add(i, 'days').format('YYYY-MM-DD')
        )

        const days = []
        const yAxisCurrent = []
        const yAxisPrevious = []
        const numberOfDays = moment().daysInMonth()
        for (let index = 0; index < numberOfDays; index++) {
          days.push(index + 1)
          const currentDate = monthlyNumberDaysByDays[index]
          const previousDate = previousMonthlyNumberDaysByDays[index]

          const currentDayQty = currentData?.days.find(
            (day) => day.dateFormatted === currentDate
          )

          const previousDayQty = previousData?.days.find(
            (day) => day.dateFormatted === previousDate
          )
          yAxisCurrent.push(currentDayQty ? currentDayQty.qty : 0)
          yAxisPrevious.push(previousDayQty ? previousDayQty.qty : 0)
        }

        dispatch(
          setComparativeData({
            yAxisCurrent,
            yAxisPrevious,
            xAxis: monthlyNumberDaysByDays
          })
        )
      }

      if (filters.period === 'range') {
        const setData = res?.period_tickets || []

        const yAxisPrevious = []

        const [days, yAxisCurrent] = setData.reduce(
          (acc, data) => {
            acc[0].push(data.dateFormatted)
            acc[1].push(data.qty)
            return acc
          },
          [[], []]
        )

        dispatch(
          setComparativeData({
            yAxisCurrent,
            yAxisPrevious,
            xAxis: days
          })
        )
      }
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
