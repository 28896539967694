import { getCommerceData } from '@utils'

import {
  parkingTransactionsListService,
  parkingLotTransactionsListService,
  parkingWithdrawService,
  tollWithdrawService,
  parkingLotWithdrawService,
  technologyWithdrawService
} from '@services/wallet'

export const getTransactionsListService = () => {
  const { category } = getCommerceData()

  const serviceByCategory = {
    estacionamiento: parkingLotTransactionsListService,
    parquimetro: parkingTransactionsListService
  }
  const defaultService = parkingLotTransactionsListService

  return serviceByCategory[category] || defaultService
}

export const getWithdrawService = () => {
  const { category } = getCommerceData()

  const serviceByCategory = {
    peaje: tollWithdrawService,
    parquimetro: parkingWithdrawService,
    estacionamiento: parkingLotWithdrawService,
    seguros: parkingLotWithdrawService,
    escuela: parkingLotWithdrawService,
    salud: parkingLotWithdrawService,
    alimentacion: parkingLotWithdrawService,
    pagodirecto: parkingLotWithdrawService,
    servicio: parkingLotWithdrawService,
    service: parkingLotWithdrawService,
    tecnologia: parkingLotWithdrawService,
    control: technologyWithdrawService
  }
  const defaultService = parkingLotWithdrawService
  return { fn: serviceByCategory[category] || defaultService, category }
}
